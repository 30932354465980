<template>
  <v-card dark class="white--text program-setup-wizard__dialog program-setup-wizard__site pb-8">
    <div class="d-flex justify-center pt-9">
      <v-icon color="yellow" x-large>mdi-lightning-bolt</v-icon>
    </div>
    <v-card-title class="font-weight-bold text-h4 justify-center pt-4 pb-2"
      ><h4>Setup program with AI</h4></v-card-title
    >
    <v-container>
      <v-row justify="center" class="">
        <v-col class="mt-auto mb-auto" cols="12" sm="10" md="8">
          <v-text-field
            v-model="inputWebsite"
            label="Website"
            placeholder="https://www.yourwebsite.com"
            :clearable="!loading"
            outlined
            x-large
            rounded
            class="mt-5"
            autofocus
            hide-details
            density="comfortable"
            variant="filled"
            color="white"
            :disabled="loading"
            de
            @keyup.enter="submitSite"
          >
            <template #append-outer>
              <v-fade-transition leave-absolute class="ml-2">
                <v-icon :disabled="loading" color="white" @click="submitSite">mdi-send</v-icon>
              </v-fade-transition>
            </template>
            <!-- TODO: Loading Icon v-progress-circular -->
            <!-- TODO: Success details  -->
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-8 program-setup-wizard__site-callout" no-gutters>
        <v-col class="program-setup-wizard__dialog--tip-description" cols="12" sm="10" md="8">
          <p v-if="!loading || errors" class="pt-3">
            Use this tool to use AI to automatically generate a program to offer on the PilotCity
            Platform
          </p>
          <v-progress-linear
            v-if="loading && !errors"
            v-model="loadingDataValue.value"
            :indeterminate="loadingDataValue.value < 1"
            color="white"
          />
          <p v-if="loading && !errors" class="pt-3">
            {{ loadingDataValue.message }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { defineComponent, reactive, nextTick } from '@vue/composition-api';

export default defineComponent({
  name: 'SetupSiteWizard',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const inputWebsite = '';
    const loadingDataValue = reactive({
      value: 0,
      message: 'Thanks!\nSuggesting possible program descriptions'
    });
    async function submitSite() {
      emit('submitSite', this.inputWebsite);
      await nextTick();
      await this.loadingData();
    }
    async function loadingData(duration = 30, parts = 5) {
      const messages = [
        'Submitting URL',
        'Retrieving Data',
        'Analyzing keywords',
        `Analyzing ${inputWebsite} description`,
        'Generating Scopes'
      ];
      const step = duration / parts;
      for (let n = 0, i = 0; n < duration; n += step, i++) {
        if (!props.loading) break;
        // eslint-disable-next-line no-await-in-loop
        await new Promise(_res => {
          setTimeout(() => {
            this.loadingDataValue.value = (n / 30) * 100;
            this.loadingDataValue.message = messages[i];
            _res();
          }, step * 1000);
        });
      }
      this.loadingDataValue.value = 95;
      this.loadingDataValue.message = 'Finishing Up..';
    }
    return {
      inputWebsite,
      loadingDataValue,
      loadingData,
      submitSite
    };
  }
});
</script>
